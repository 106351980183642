import Link from 'next/link';
import React, { ReactNode, useEffect, useState } from 'react';
import { Input } from 'components';
import { useForm } from 'react-hook-form';
import { useAuth } from 'providers/auth.provider';
import { useRouter } from 'next/router';
import { Button } from 'modules/common';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureAsync } from 'hooks';

type FormInput = {
  email: string;
  password: string;
};

const LoginContainer = ({ children }) => {
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
      </div>
    </div>
  );
};

const FirebaseLogin = () => {
  const { register, handleSubmit } = useForm<FormInput>();
  const { login } = useAuth();
  const router = useRouter();
  const [formError, setFormError] = useState('');

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setFormError('');

    try {
      await login(email, password);
      router.push('/dashboard');
    } catch ({ code }) {
      if (code === 'auth/user-not-found' || code === 'auth/wrong-password') {
        setFormError('Email or password are incorrect.');
      } else {
        setFormError('An unexpected error occurred.');
      }
    }
  });

  return (
    <LoginContainer>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form className="space-y-6" onSubmit={onSubmit}>
          {formError && <div className="mb-4">{formError}</div>}
          <div>
            <Input label="Email" htmlProps={{ type: 'email' }} register={register('email', { required: 'Please enter an email' })} />
          </div>

          <div>
            <Input label="Password" htmlProps={{ type: 'password' }} register={register('password', { required: 'Please enter a password' })} />
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link href="/forgot-password" className="font-medium text-gray-600 hover:text-gray-500">
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <Button type="submit">Sign in</Button>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
};

const Auth0Login = ({ redirect, error }: { redirect: boolean; error?: string }) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout({ logoutParams: { returnTo: `${window.location.origin}/login${!redirect ? '?redirect=false' : ''}` } });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated && redirect) {
      loginWithRedirect();
    }
  }, [isAuthenticated]);

  if (redirect) return null;

  return (
    <div>
      <LoginContainer>
        <div className="flex items-center justify-center">
          <Button onClick={() => loginWithRedirect()}>Sign in</Button>
        </div>
      </LoginContainer>
      {error && (
        <p className="text-center text-red-500">
          {error} Please contact support{' '}
          <a className="underline" href="mailto:help@taper.ai">
            help@taper.ai
          </a>
          .
        </p>
      )}
    </div>
  );
};

const LoginPage = () => {
  const { query } = useRouter();
  const redirect = !(query?.redirect === 'false');
  const { enabled: auth0AuthFF, loading: auth0AuthFFLoading } = useFeatureAsync('AUTH_AUTH0');

  if (auth0AuthFFLoading) return null;

  return (
    <>
      {auth0AuthFF && <Auth0Login redirect={redirect} error={query?.error as string} />}
      {!auth0AuthFF && <FirebaseLogin />}
    </>
  );
};

LoginPage.getLayout = (children: ReactNode) => <>{children}</>;

export default LoginPage;
